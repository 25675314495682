<template>
    <div class="comments" :style="commentStyle" :class="commentClass">
        <header class="comment-header comment-section">
            <div class="comment-avatar">
                <InternalType :absolute="false" :item="{ internalType: 'email' }" />

                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <div v-on="on">
                            <CommonAvatar v-if="item.userId" :userId="item.userId" :size="32" />
                            <SystemAvatar v-else :size="32" />
                        </div>
                    </template>
                    <span>{{ item.userId ? item.userName : $t('comment.system') }}</span>
                </v-tooltip>
            </div>
            <section>
                <div class="comment-info">
                    <div class="comment-from text-truncate">{{ item.from }}</div>
                    <div class="comment-top-field comment-date text-truncate" :class="getChipClassName(item)">
                        <v-icon v-if="!item.sent && isBeforeSendTimeAndScheduled" size="medium" color="white">
                            mdi-timer-sand
                        </v-icon>

                        <v-progress-circular
                            v-if="!item.sent && !item.failed && isAfterSendDateTime"
                            color="var(-v-gray2-base)"
                            indeterminate
                            size="10"
                            width="2"
                        />
                        <v-icon v-if="item.failed" size="medium">mdi-alert</v-icon>
                        {{ item.failed ? $t('comment.failed') : commentDirection }}
                    </div>
                    <span class="comment-top-field comment-pre-wrap">{{ to }}</span>

                    <span v-if="isBeforeSendTimeAndScheduled" class="comment-top-field comment-date text-truncate">
                        {{ formatDate(item.sendDateTime) }}
                    </span>

                    <span v-else class="comment-top-field comment-date text-truncate">
                        {{ formatDate(item.dateTime) }}
                    </span>
                </div>
            </section>
        </header>

        <section v-if="item.subject" class="comment-title comment-section comment-tries">
            {{ formatSubject(item.subject) }}
            <div>
                <span v-if="item.tries" class="comment-tries-text">{{ $t('comment.tries') }} {{ item.tries }}</span>
            </div>
        </section>
        <section class="comment-content">
            <div
                v-if="item.contentType === contentTypes.HTML"
                v-shadow-dom="item.comment"
                class="comment-inner-content"
                @link-clicked="linkClicked"
            ></div>
            <div v-else class="plaintext-comment">{{ item.comment }}</div>

            <!-- Button to show rest of email thread-->
            <button
                v-if="item.appendedComment"
                class="show-thread-button"
                :title="showAppendedComment ? $t('comment.hideRestOfThread') : $t('comment.showRestOfThread')"
                @click="showAppendedComment = !showAppendedComment"
            >
                <!-- Uses midline horizontal ellipsis (dots but vertically centered) -->
                &#8943;
            </button>

            <!-- The appended comment (rest of email thread) -->
            <div v-if="showAppendedComment" class="appended-container">
                <div class="line"></div>
                <div class="appended">
                    <div
                        v-if="item.contentType === contentTypes.HTML"
                        v-shadow-dom="item.appendedComment"
                        class="comment-inner-content"
                        @link-clicked="linkClicked"
                    ></div>
                    <div v-else class="plaintext-comment">{{ item.appendedComment }}</div>
                </div>
            </div>
            <CommentSignature v-if="item.outgoing && item.signature" :item="item" />
        </section>

        <section v-if="item.attachments.length" class="comment-attachment">
            <ReadMore :items="item.attachments">
                <template #default="{ item }">
                    <FilePreview
                        :file="item"
                        :cfg="config"
                        :miniature="userSettings && userSettings.cases.imagePreviewMiniature.active"
                        class="file-preview"
                    />
                </template>
            </ReadMore>
        </section>
        <section class="comment-action-buttons d-flex">
            <CommentActions :active="activeButtons" :item="item" />

            <section class="comment-right-actions">
                <AdminMenu :item="item" />

                <div v-if="item.tries && !item.sent" class="ml-auto">
                    <v-btn v-if="item.failed" class="mr-2" text icon color="red" @click="deleteComment">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-chip :outlined="true" v-bind="attrs" v-on="on" @click="retryComment">
                                <span class="text-truncate chip-action">{{ $t('comment.retry') }}</span>
                            </v-chip>
                        </template>
                        <span>
                            {{
                                item.failed
                                    ? $t('comment.retryTooltipFailed')
                                    : $t('comment.retryTooltipTrying', {
                                          time: $tc('comment.retryMinutes', Math.ceil(nextRetryAttemptInMinutes)),
                                      })
                            }}
                        </span>
                    </v-tooltip>
                </div>
            </section>
        </section>
        <v-dialog v-model="switchEmailDialog" width="600">
            <v-card>
                <v-card-title>{{ $t('comment.switchAddress') }}</v-card-title>
                <v-card-text>
                    {{ $t('comment.switchAddressText') }}
                    <v-select :items="systemEmails" v-bind="switchEmailChosenAddress" />
                    <v-btn
                        @click="
                            switchEmailDialog = false;
                            resetTries();
                        "
                    >
                        {{ $t('comment.retry') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import { format } from 'date-fns';
    import { millisecondsInMinute } from 'date-fns/constants';
    import { mapState } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';

    import config from '@/app/config';

    import InternalType from '@/components/Cases/List/InternalType.vue';
    import FilePreview from '@/components/Cases/CaseComments/FilePreview.vue';
    import ReadMore from '@/components/Cases/CaseComments/ReadMore.vue';
    import CommentActions from '@/components/Cases/CaseComments/CommentActions.vue';
    import CommentSignature from '@/components/Cases/CaseComments/CommentSignature.vue';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import SystemAvatar from '@/components/Global/SystemAvatar.vue';
    import { resetCommentTries, deleteFailedComment } from '@/api/comments/comments';
    import { contentTypes } from '@/enums/comments.enums';

    import commentsHelper from '@/helpers/comments/comments.helper';
    import AdminMenu from '@/components/Cases/Comments/AdminMenu.vue';

    export default {
        components: {
            InternalType,
            FilePreview,
            ReadMore,
            CommentActions,
            CommentSignature,
            CommonAvatar,
            SystemAvatar,
            AdminMenu,
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },
            isSubcase: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                config,
                switchEmailDialog: false,
                switchEmailChosenAddress: undefined,
                contentTypes,
                showAppendedComment: false,
            };
        },

        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
                repliedComment: (state) => state.Comments.repliedComment,
                systemEmails: (state) => state.Cases.systemEmails,
                dateNow: (state) => state.Cases.dateNow,
                tiptapState: (state) => state.Comments.tiptapState,
            }),

            activeButtons() {
                const buttons = ['answer', 'answer-all', 'chat-gpt'];

                if (!this.isSubcase) {
                    buttons.push('forward');
                }

                return buttons;
            },

            to() {
                // format To: email, cc: email, bcc: email
                let result = `${this.$t('comment.to')}`;

                if (this.item.to && this.item.to.length > 0) {
                    result += ` ${commentsHelper.convertToString(this.item.to)}`;
                } else if (this.item.receiver) {
                    result += ` ${this.item.receiver}`;
                }

                if (this.item.ccTo && this.item.ccTo.length > 0) {
                    result += `\n${this.$t('comment.cc')}`;
                    result += ` ${commentsHelper.convertToString(this.item.ccTo)}`;
                }

                if (this.item.bccTo && this.item.bccTo.length > 0) {
                    result += `\n${this.$t('comment.bcc')}`;
                    result += ` ${commentsHelper.convertToString(this.item.bccTo)}`;
                }

                return result;
            },

            nextRetryAttemptInMinutes() {
                return (new Date(this.item.retryAt) - this.dateNow) / millisecondsInMinute;
            },

            commentStyle() {
                return {
                    justifySelf: this.item.outgoing ? 'end' : 'start',
                };
            },

            commentClass() {
                return {
                    outgoing: this.item.outgoing,
                    incoming: !this.item.outgoing,
                    repliedComment: this.isRepliedComment,
                };
            },

            isScheduled() {
                return this.item.sendDateTime && new Date(this.item.sendDateTime) > new Date();
            },

            isAfterSendDateTime() {
                return this.item.sendDateTime && new Date(this.item.sendDateTime) < new Date();
            },

            isBeforeSendTimeAndScheduled() {
                return this.isScheduled && !this.isAfterSendDateTime;
            },

            commentDirection() {
                if (this.isScheduled) {
                    return this.$t('comment.scheduled');
                }

                if (!this.item.sent) {
                    return this.$t('comment.sending');
                }

                return this.item.outgoing ? this.$t('comment.outgoing') : this.$t('comment.incoming');
            },

            isRepliedComment() {
                return this.item.id === this.repliedComment && this.tiptapState === 'open';
            },
        },

        methods: {
            getChipClassName(item) {
                if (this.isBeforeSendTimeAndScheduled) {
                    return 'scheduled-chip';
                }
                if (item.failed) {
                    return 'failed-chip';
                }
                if (item.outgoing) {
                    return 'outgoing-chip';
                }
                return 'incoming-chip';
            },
            formatSubject(subject) {
                const MAX_LENGTH = 300;
                const START_INDEX = 0;

                if (subject.length > MAX_LENGTH) {
                    return subject.slice(START_INDEX, MAX_LENGTH) + '...';
                }
                return subject;
            },

            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },
            getDay,
            format,
            async resetTries() {
                try {
                    await resetCommentTries(this.item.id);
                } catch (error) {
                    console.error('Error resetting comment tries', error);
                    this.$toasted.show(this.$t('comment.retryError'), { type: 'error' });
                }
            },
            async retryComment() {
                if (this.systemEmails.includes(this.item.from)) {
                    await this.resetTries();
                } else {
                    this.switchEmailDialog = true;
                }
            },
            async deleteComment() {
                try {
                    await deleteFailedComment(this.item.id);
                } catch (error) {
                    console.error('Error deleting comment', error);
                    this.$toasted.show(this.$t('comment.deleteError'), { type: 'error' });
                }
            },
            async linkClicked(event) {
                const href = event?.detail?.content?.getAttribute('href');
                if (!href) return;

                // Create an anchor element to extract URL parts
                const link = document.createElement('a');
                link.href = href;

                // Extract the current host and protocol
                const currentHost = window.location.host;
                const currentProtocol = window.location.protocol;

                // Check if the link is external
                const isExternalLink = link.host !== currentHost || link.protocol !== currentProtocol;

                // Proceed with the confirmation dialog only for external links
                if (isExternalLink) {
                    const { confirmed } = await this.$global.dialogs.showConfirmationDialog({
                        title: this.$t('comment.leaveSite'),
                        message: this.$t('comment.continueTo') + href,
                        confirmText: this.$t('global.btn.confirm'),
                        declineText: this.$t('global.btn.cancel'),
                    });

                    if (!confirmed) return;

                    window.open(href, '_blank');
                } else {
                    // Open internal links without confirmation
                    window.location.href = href;
                }
            },
        },
    };
</script>
<style scoped lang="scss">
    .comment-right-actions {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .comment-action-buttons {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 16px;
        display: flex;
        flex: 1;
        justify-content: space-between;
    }
    .comment-attachment {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .comment-top-field {
        font-size: 12px;
        gap: 4px;
        display: flex;
        align-items: flex-start;
    }

    .comment-pre-wrap {
        word-break: break-word !important;
        white-space: pre-wrap !important;
        overflow-wrap: break-word !important;
    }

    .comments {
        padding: 32px;
        border-radius: 12px;
        width: 100%;
        max-width: 800px;
        gap: 4px;
        font-size: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        transition: all 0.2s ease;
    }
    .comment-avatar {
        color: white;
        border: 0px solid var(--v-accent2-base);
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 40px;
        gap: 8px;
    }

    .comment-header {
        display: grid;
        grid-template-columns: auto 1fr;
        padding-bottom: 8px;
        gap: 8px;
    }

    .comment-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr auto;
        font-size: 0.8rem;
        color: var(--v-gray2-base);
    }

    .comment-date {
        justify-self: flex-end;
    }

    .comment-from {
        color: var(--v-gray4-base);
        font-size: 1rem;
    }

    .comment-section {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .comment-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px 0;
        font-size: 1rem;
        position: relative;
        box-sizing: border-box;

        .show-thread-button {
            margin-right: auto;
            display: flex;
            padding: 0px 4px;
            border-radius: 2px;
            background-color: rgba(226, 226, 226, 0.555);
            transition: background-color 0.2s ease;
            height: 10px;
            align-items: center;
            margin-bottom: 1em;

            &:hover {
                background-color: rgba(226, 226, 226, 0.855);
            }
        }

        .appended-container {
            display: flex;
            gap: 0.5em;

            .line {
                width: 0.25em;
                background-color: rgb(230 230 230);
            }

            .appended {
                margin-block: 1em;
                max-height: 500px;
                overflow-y: scroll;
                flex: 1;
                background-color: rgb(245 245 245 / 40%);
                padding: 0.5em;
                border-radius: 2px;
            }
        }
    }

    .comment-inner-content {
        position: relative;
        all: initial;
        font-family: inherit;
    }

    .comment-title {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 1.3rem;
        font-weight: 300;
        display: flex;
        flex-direction: row;
    }

    .file-preview {
        max-width: 200px;
    }

    .comments-row {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        transition: all 0.3s;
        width: 100%;
    }

    .repliedComment {
        box-shadow: 0 0 40px 15px rgba(0, 0, 0, 0.15);
        margin: 1em 2em;
        transition: all 0.5s;
    }

    .comment-tries {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .comment-tries-text {
        font-size: 12px;
        color: var(--v-gray2-base);
    }
    .chip-action {
        color: var(--v-gray2-base);
        font-size: 12px;
    }

    .plaintext-comment {
        white-space: pre-wrap;
        word-break: break-word;
    }
</style>

<i18n lang="json">
{
    "en": {
        "comment": {
            "to": "To:",
            "cc": "Cc:",
            "bcc": "Bcc:",
            "outgoing": "Outgoing",
            "incoming": "Incoming",
            "sending": "Sending",
            "scheduled": "Scheduled",
            "continueTo": "You will continue to: ",
            "leaveSite": "Do you want to open the link?",
            "tries": "Tries: ",
            "failed": "Failed",
            "retry": "Retry",
            "retryTooltipFailed": "Retry sending the email",
            "retryMinutes": "{n} minute | {n} minutes",
            "retryTooltipTrying": "Next attempt to send is in {time}, press to retry now",
            "retryError": "Couldn't reset tries, please try again later",
            "deleteError": "Couldn't delete the comment, please try again later",
            "switchAddress": "Switch address",
            "switchAddressText": "This email is marked to be sent from an address that doesnt exist, please choose an new address to send from:",
            "showRestOfThread": "Show rest of the email thread",
            "hideRestOfThread": "Hide rest of the email thread"
        }
    },
    "sv": {
        "comment": {
            "to": "Till:",
            "cc": "Kopia:",
            "bcc": "Hemlig kopia:",
            "outgoing": "Utgående",
            "incoming": "Inkommande",
            "sending": "Skickar",
            "scheduled": "Schemalagd",
            "continueTo": "Du kommer att fortsätta till: ",
            "leaveSite": "Vill du öppna länken?",
            "tries": "Försök: ",
            "failed": "Misslyckades",
            "retry": "Försök igen",
            "retryTooltipFailed": "Försök att skicka e-postmeddelandet igen",
            "retryMinutes": "{n} minut | {n} minuter",
            "retryTooltipTrying": "Nästa försök att skicka är om {time}, tryck för att försöka nu",
            "retryError": "Kunde inte återställa försök, försök igen senare",
            "deleteError": "Kunde inte ta bort kommentaren, försök igen senare",
            "switchAddress": "Byt adress",
            "switchAddressText": "Detta e-postmeddelande är vald att skickas från en adress som inte finns, välj en ny adress att skicka från:",
            "showRestOfThread": "Visa resten av mail-tråden",
            "hideRestOfThread": "Dölj resten av mail-tråden"
        }
    }
}
</i18n>
