// Import i18n instance
import client from '@/api/client/client';
import i18n from '../../i18n';
import { clientTypes, events, states } from '../../enums/client.enums';
import { events as casesEvents } from '../../enums/cases.enums';

import { modalStates } from '../../enums/client.enums';
import { formTypes } from '../../enums/creator.enums';

// State object
const getDefaultState = () => ({
    actionButtonsClient: [
        {
            textKey: 'creator.bindClient',
            icon: 'mdi-arrow-right',
            color: 'var(--v-gray2-base)',
            value: 'bindClient',
            tooltip: i18n.t('creator.bindClientTooltip'),
        },
        {
            textKey: 'creator.mergeClient',
            icon: 'mdi-arrow-left-right',
            color: 'var(--v-gray2-base)',
            value: 'mergeClient',
            tooltip: i18n.t('creator.bindClientTooltip'),
        },
    ],

    actionButtonsShadow: [
        {
            textKey: 'creator.mergeClient',
            icon: 'mdi-arrow-left-right',
            color: 'var(--v-gray2-base)',
            value: 'mergeClient',
            tooltip: i18n.t('creator.bindClientTooltip'),
        },
    ],

    actionButtonsContact: [
        {
            textKey: 'creator.bindContact',
            icon: 'mdi-arrow-right',
            color: 'var(--v-gray2-base)',
            value: 'bindContact',
            tooltip: i18n.t('creator.bindContactTooltip'),
        },
        {
            textKey: 'creator.mergeClient',
            icon: 'mdi-arrow-left-right',
            color: 'var(--v-gray2-base)',
            value: 'mergeClient',
            tooltip: i18n.t('creator.bindClientTooltip'),
        },
    ],

    modal: null,

    currentSelectedClient: null,

    clientCardState: localStorage.getItem('clientCardState') || states.OPEN,

    customerTypes: [],

    clientsFamily: [],

    clientsChildren: [],

    clientTimeline: [],

    clientTypes: [],
});

const state = getDefaultState();

// Getter functions
const getters = {
    selectedClientId: (state) => state.currentSelectedClient?.clientId,
    selectedClientSSN: (state) => state.currentSelectedClient?.socialSecurityNumber,
};

// Actions
const actions = {
    SOCKET_changeInCurrentCase({ commit }, payload) {
        if (payload.type === casesEvents.UPDATE_CASE) {
            const currentCase = payload.data.case;
            const subCases = payload.data.subCases;

            commit('UPDATE_UNREADMESSAGES_TIMELINE', {
                caseId: currentCase.caseId,
                unreadMessages: currentCase.unreadMessages,
            });

            for (const subCase of subCases) {
                commit('UPDATE_UNREADMESSAGES_TIMELINE', {
                    caseId: subCase.caseId,
                    unreadMessages: subCase.unreadMessages,
                });
            }
        }
    },

    SOCKET_clientUpdate({ dispatch, commit, rootState }, payload) {
        if (payload.type === events.UPDATE_CLIENT) {
            const clientData = payload.data.client.data;

            const clientOnCase = rootState.Cases?.clientNowOnCard?.assignedToClientId;

            if (!clientOnCase) return;

            dispatch('getClientsFamily', { id: clientOnCase, limit: 10, page: 1 });

            if (state.currentSelectedClient.clientId !== clientData.clientId) return;

            commit('SET_CURRENT_SELECTED_CLIENT', clientData);

            const { clientNowOnCard } = rootState.Cases;

            // If the selected client is the same as the client on the card update the client on the card
            if (
                state.currentSelectedClient?.clientId &&
                clientNowOnCard?.data?.clientId &&
                state.currentSelectedClient.clientId === clientNowOnCard.data.clientId
            ) {
                const newClientOnCard = {
                    ...clientNowOnCard,
                    assignedTo: payload.data.assignedTo,
                    assignedToType: payload.data.assignedToType,
                    data: payload.data.client.data,
                    contacts: payload.data.client.contacts,
                };
                dispatch('Cases/setClientOnCard', newClientOnCard, { root: true });
                return;
            }
        }

        if (payload.type === events.NEW_CLIENT) {
            dispatch('Cases/setClientOnCard', payload.data.client, { root: true });
            commit('SET_CURRENT_SELECTED_CLIENT', payload.data.client.data);
            dispatch('getClientsFamily', { id: payload.data.client.data.clientId, limit: 10, page: 1 });
        }
    },

    unsubscribeToClient(_, oldVal) {
        if (oldVal?.clientId) {
            this._vm.$socket.emit('unsubscribeToClient', oldVal.clientId);
        }
    },

    subscribeToClient(_, newVal) {
        if (newVal?.clientId) {
            this._vm.$socket.emit('subscribeToClient', newVal.clientId);
        }
    },

    async getClientByCaseId(_, id) {
        try {
            const result = await client.getClientByCaseId(id);
            return result.data;
        } catch (error) {
            console.error('Error fetching client by case id', error);
            return null;
        }
    },

    async getClients(_, payload) {
        try {
            const result = await client.getClients(payload);
            return result.data;
        } catch (error) {
            console.error('Error fetching clients', error);
            return null;
        }
    },

    async getAddresses(_, payload) {
        try {
            const result = await client.getAddresses(payload);
            return result.data;
        } catch (error) {
            console.error('Error fetching addresses', error);
            return null;
        }
    },

    async updateClient({ dispatch, commit, rootState }, clientData) {
        try {
            delete clientData.type;

            commit('SET_CURRENT_SELECTED_CLIENT', { ...state.currentSelectedClient, ...clientData });

            await client.updateClientFields(clientData);

            this._vm.$toasted.show(i18n.t('client.updated'), {
                icon: 'mdi-content-save',
                type: 'success',
            });

            return clientData;
        } catch (error) {
            if (error?.response?.data?.name) {
                const message = i18n.t('client.clientAlreadyExists', { client: error.response.data.name });
                // A client already exists with the field we are trying to update and with this name
                this._vm.$toasted.show(message, {
                    icon: 'cancel',
                    type: 'error',
                });
                // If we fail to set the data we get the data again from the server
                dispatch('Cases/clientCard', rootState.Cases.selectedCase.case.caseId, { root: true });
                return null;
            }

            this._vm.$toasted.show(i18n.t('client.updatedError'), {
                icon: 'cancel',
                type: 'error',
            });

            return null;
        }
    },

    setCurrentSelectedClient({ commit }, client) {
        commit('SET_CURRENT_SELECTED_CLIENT', client);
    },

    handleAction({ dispatch, commit, rootState }, action) {
        switch (action.value) {
            case 'openCaseAssignModal': {
                commit('SET_MODAL_STATE', modalStates.CASE_ASSIGN);
                break;
            }

            case 'showContactPerson': {
                commit('SET_MODAL_STATE', modalStates.CONTACT_PERSON);
                break;
            }

            case 'connectClientToCase': {
                if (!rootState.Cases?.selectedCase?.case) return;

                const { caseId } = rootState.Cases.selectedCase.case;

                dispatch(
                    'Creator/openForm',
                    {
                        id: formTypes.CONNECT_CLIENT_TO_CASE,
                        keys: { caseId: { value: caseId } },
                    },
                    { root: true },
                );
                break;
            }

            case 'bindClient': {
                const { clientId, name } = state.currentSelectedClient;
                dispatch(
                    'Creator/openForm',
                    { id: formTypes.BIND_CLIENT, keys: { from: { value: clientId }, name: { value: name } } },
                    { root: true },
                );
                break;
            }

            case 'mergeClient': {
                const { clientId, name } = state.currentSelectedClient;
                dispatch(
                    'Creator/openForm',
                    {
                        id: formTypes.MERGE_CLIENT,
                        keys: { from: { value: clientId }, to: { clientId }, name: { value: name } },
                    },
                    { root: true },
                );
                break;
            }

            case 'bindContact': {
                const { clientId, name } = state.currentSelectedClient;
                dispatch(
                    'Creator/openForm',
                    { id: formTypes.BIND_CONTACT, keys: { from: { value: clientId }, name: { value: name } } },
                    { root: true },
                );
                break;
            }

            case 'openCrm': {
                commit('SET_MODAL_STATE', modalStates.CRM);
                break;
            }

            case 'changeContent': {
                commit('SET_MODAL_STATE', modalStates.CONTENT);
                break;
            }

            case 'openSidebarViewRecordings': {
                commit('SET_MODAL_STATE', modalStates.RECORDINGS);
                break;
            }

            case 'queueIdentifierAssign': {
                commit('SET_MODAL_STATE', modalStates.QUEUE_ASSIGN);
                break;
            }
            case 'iframe': {
                commit('SET_MODAL_STATE', modalStates.IFRAME);
                break;
            }

            default: {
                commit('SET_MODAL_STATE', null);
                break;
            }
        }
    },

    toggleClientCard({ commit }) {
        commit('TOGGLE_CLIENT_CARD');
    },

    async fetchCustomerTypes({ commit }, { limit, page }) {
        try {
            const offset = (page - 1) * limit;
            const result = await client.getCustomerTypes({ limit, offset });

            const { data } = result;

            const mappedData = data.map((item) => {
                return {
                    ...item,
                    value: item.name,
                };
            });

            if (page === 1) {
                commit('SET_CUSTOMER_TYPES', mappedData);
            } else {
                commit('SET_CUSTOMER_TYPES', state.customerTypes.concat(mappedData));
            }

            return result.data;
        } catch (error) {
            console.error('Error fetching customer types', error);
            return null;
        }
    },

    async fetchClientTypes({ commit }, { page }) {
        try {
            if (page === 1) {
                const result = await client.getClientTypes();
                const { data } = result;

                const mappedData = data.map((item) => {
                    return {
                        ...item,
                        value: item.id,
                    };
                });
                commit('SET_CLIENT_TYPES', mappedData);
                return result.data;
            }
            return [];
        } catch (error) {
            console.error('Error fetching client types', error);
            return null;
        }
    },

    async createCustomerType(_, name) {
        try {
            await client.createCustomerType(name);
            return true;
        } catch (error) {
            console.error('Error creating customer type', error);

            if (error?.response?.status === 409) {
                this._vm.$toasted.show(i18n.t('client.duplicatedField'), {
                    icon: 'cancel',
                    type: 'error',
                });
                return false;
            }

            this._vm.$toasted.show(i18n.t('client.createErrorField'), {
                icon: 'cancel',
                type: 'error',
            });

            return false;
        }
    },

    async getClientsFamily({ dispatch, commit }, { id, limit, page }) {
        const offset = (page - 1) * limit;
        try {
            const result = await client.getClientsFamily(id, limit, offset);

            if (page === 1) {
                commit('SET_CLIENTS_FAMILY', result.data);
            } else {
                commit('SET_CLIENTS_FAMILY', state.clientsFamily.concat(result.data));
            }

            for (const client of result.data) {
                dispatch('subscribeToClient', { clientId: client.id });
            }

            return result.data;
        } catch (error) {
            commit('SET_CLIENTS_FAMILY', []);
            console.error('Error fetching clients parents', error);
            return null;
        }
    },

    async getClientsChildren({ commit }, { id, limit, page, clientTypeIds = [] }) {
        const offset = (page - 1) * limit;
        try {
            const result = await client.getClientsChildren(id, limit, offset, clientTypeIds);

            if (page === 1) {
                commit('SET_CLIENTS_CHILDREN', result.data);
            } else {
                commit('SET_CLIENTS_CHILDREN', state.clientsChildren.concat(result.data));
            }

            return result.data;
        } catch (error) {
            commit('SET_CLIENTS_CHILDREN', []);
            console.error('Error fetching clients children', error);
            return null;
        }
    },

    async getClient({ commit }, id) {
        try {
            const result = await client.getClientV2(id);
            commit('SET_CURRENT_SELECTED_CLIENT', result.data);

            return result.data;
        } catch (error) {
            commit('SET_CURRENT_SELECTED_CLIENT', null);
            console.error('Error fetching client', error);
            return null;
        }
    },

    async getTimeline({ commit }, { id, limit, page }) {
        const offset = (page - 1) * limit;

        try {
            const result = await client.getTimeline(id, limit, offset);

            if (page === 1) {
                commit(
                    'SET_CLIENT_TIMELINE',
                    result.data.cases.map((c) => ({ ...c.data, id: c.data.caseId, allowed: c.allowed })),
                );
            } else {
                commit(
                    'SET_CLIENT_TIMELINE',
                    state.clientTimeline.concat(
                        result.data.cases.map((c) => ({ ...c.data, id: c.data.caseId, allowed: c.allowed })),
                    ),
                );
            }

            return result.data;
        } catch (error) {
            commit('SET_CLIENT_TIMELINE', []);
            console.error('Error fetching timeline', error);
            return null;
        }
    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
};

// Mutations
const mutations = {
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
    },

    SET_CLIENT_TYPES(state, clientTypes) {
        state.clientTypes = clientTypes;
    },

    SET_CLIENT_TIMELINE(state, timeline) {
        state.clientTimeline = timeline;
    },

    SET_CLIENTS_CHILDREN(state, clientsChildren) {
        state.clientsChildren = clientsChildren;
    },

    SET_CLIENTS_FAMILY(state, clientsFamily) {
        state.clientsFamily = clientsFamily;
    },

    SET_CUSTOMER_TYPES(state, customerTypes) {
        state.customerTypes = customerTypes;
    },

    SET_MODAL_STATE(state, modalState) {
        state.modal = modalState;
    },

    SET_CURRENT_SELECTED_CLIENT(state, client) {
        state.currentSelectedClient = client;
    },

    TOGGLE_CLIENT_CARD(state) {
        if (state.clientCardState === states.OPEN) {
            state.clientCardState = states.CLOSED;
            localStorage.setItem('clientCardState', states.CLOSED);
            return;
        }
        state.clientCardState = states.OPEN;
        localStorage.setItem('clientCardState', states.OPEN);
    },

    UPDATE_UNREADMESSAGES_TIMELINE(state, { caseId, unreadMessages }) {
        const index = state.clientTimeline.findIndex((c) => c.caseId === caseId);
        if (index >= 0) {
            state.clientTimeline[index].unreadMessages = unreadMessages;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
