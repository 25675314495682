<template>
    <div>
        <div v-if="loadingState === 'loading'">
            <v-skeleton-loader
                v-for="n in 3"
                :key="n"
                type="list-item"
                :loading="loadingState === 'loading'"
            ></v-skeleton-loader>
        </div>
        <div v-else-if="loadingState === 'error'">{{ $t('errorLoadingAnimals') }}</div>
        <div v-else>
            <v-list-item-group>
                <I2iClaimListItem v-for="(claim, index) in claims" :key="index" :claim="claim" />
            </v-list-item-group>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import api from '@/api/integrations/integration';
    import I2iClaimListItem from './I2iClaimListItem.vue';
    import { Claim } from '@/types/integrations/i2i.types';

    const states = {
        LOADING: 'loading',
        LOADED: 'loaded',
        ERROR: 'error',
    };

    export default defineComponent({
        components: {
            I2iClaimListItem,
        },
        data() {
            return {
                loadingState: states.LOADING,
                policy: null as any,
                claims: [] as Claim[],
                selectedClaim: null as Claim | null,
            };
        },
        computed: {
            partyId(): string {
                // @ts-ignore
                return this.$store.state.Integrations.partyId;
            },
        },
        watch: {
            partyId: {
                handler(newVal) {
                    if (!newVal) {
                        this.claims = [];
                    }
                    if (newVal) {
                        this.getCustomerClaims(newVal);
                    }
                },
                immediate: true,
            },
        },
        methods: {
            async getCustomerClaims(partyId: string) {
                try {
                    if (!partyId) {
                        this.loadingState = states.LOADED;
                        return;
                    }
                    const { data } = await api.getI2ICustomerClaims(partyId);
                    this.loadingState = states.LOADED;
                    if (!data) return;
                    this.claims = data;
                } catch (error) {
                    console.error(error);
                    this.loadingState = states.ERROR;
                }
            },
        },
    });
</script>

<i18n lang="json">
{
    "en": {
        "errorLoadingClaims": "Error loading claims"
    },
    "sv": {
        "errorLoadingClaims": "Fel vid inläsning av skador"
    }
}
</i18n>
