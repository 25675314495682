<template>
    <div class="buttons-wrapper">
        <!-- We only want to show these buttons if the client is based on i2i policy -->
        <template v-if="activeIntegrations.hasOwnProperty('I2I')">
            <template>
                <ClientCardActionButton
                    v-if="false"
                    state="I2I-quotes"
                    :tooltip-label="$t('quotes')"
                    mdi-icon="mdi-file-document-outline"
                />
                <ClientCardActionButton
                    v-if="false"
                    state="I2I-claims"
                    :tooltip-label="$t('claims')"
                    mdi-icon="mdi-plus-box-outline"
                />
            </template>

            <ClientCardActionButton
                v-if="policyId"
                state="I2I-animals"
                :tooltip-label="$t('animals')"
                mdi-icon="mdi-paw"
            />
        </template>
    </div>
</template>

<script lang="ts">
    import clientApi from '@/api/client/client';
    // @ts-ignore
    import { mapActions, mapState } from 'vuex';
    import { getImage } from '@/utils/Integrations';
    import ClientCardActionButton from '@/components/Cases/ClientCard/ClientCardActionButton.vue';

    export default {
        components: {
            ClientCardActionButton,
        },
        data() {
            return {
                activeIntegrations: {},
                policyId: null,
            };
        },
        computed: {
            ...mapState({
                sideBarView: (state: any) => state.Cases.sideBarView,
            }),
        },
        watch: {
            '$store.state.Client.currentSelectedClient.clientId': {
                async handler(newVal, oldVal) {
                    const I2iActive = this.activeIntegrations?.hasOwnProperty('I2I');

                    if (!newVal || newVal === oldVal || !I2iActive) return;

                    this.setPolicyId(null);
                    this.getClientRelations(newVal);
                },
                deep: true,
                immediate: true,
            },
        },
        async created() {
            this.activeIntegrations = await this.getActiveStates();
            if (this.activeIntegrations.hasOwnProperty('I2I')) {
                this.getClientRelations(this.$store.state.Client.currentSelectedClient.clientId);
            }
        },
        methods: {
            getImage,
            ...mapActions({
                getActiveStates: 'Integrations/getActiveStates',
                setSideBarView: 'Cases/sideBarView',
            }),
            async getClientRelations(clientId: string) {
                try {
                    const { data } = await clientApi.getClientRelations(clientId);

                    if (data?.length) {
                        const i2iRelation = data.find((relation: { source: string }) => relation.source === 'i2i');
                        const [resource, id] = i2iRelation?.externalId?.split('_');

                        if (resource === 'policy') {
                            this.setPolicyId(id);
                        } else if (resource === 'party') {
                            this.setPolicyId(null);
                            if (this.sideBarView === 'I2I-animals') {
                                this.setSideBarView('default');
                            }
                            this.setPartyId(id);
                        }
                    }
                } catch (error) {
                    console.error(error);
                    this.setPolicyId(null);
                }
            },
            setPolicyId(policyId: string) {
                this.policyId = policyId;
                // TODO: FIX
                this.$store.state.Integrations.policyId = policyId;
                // this.$store.commit('Integrations/setPolicyId', this.policyId);
            },
            setPartyId(partyId: string) {
                this.$store.state.Integrations.partyId = partyId;
            },
        },
    };
</script>

<style scoped>
    .buttons-wrapper {
        display: flex;
        gap: 8px;
    }
</style>

<i18n lang="json">
{
    "sv": {
        "quotes": "Offerter",
        "claims": "Skador",
        "animals": "Djur"
    },
    "en": {
        "quotes": "Quotes",
        "claims": "Claims",
        "animals": "Animals"
    }
}
</i18n>
